@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@font-face {
    font-family: 'dot';
    src: url('./dot.TTF') format('truetype');
}

body {
    margin: 0;
    font-family: 'Comic Neue', cursive;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url(../public/bg.webp);
    color: azure !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dot-grid {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot-grid circle {
    transition: fill 0.2s;
}

.bg-dark {
    background-color: #222 !important;
}

.text-primary {
    color: #4d96ff !important;
}

.text-danger {
    color: #ff6b6b !important;
}

.text-success {
    color: #6bcb77 !important;
}
